<div class="expertise-card" [class.expertise-card-dark]="cardDark" *ngIf="!!expertiseCard">
  <div class="expertise-card__body">
    <span class="badge badge-sm bg-primary text-uppercase mb-2">{{expertiseCard?.tag?.name}}</span>
    <h3 class="mb-2">{{expertiseCard?.title}} <span class="contry-text text-muted">{{expertiseCard?.country}}</span></h3>
    <p class="text-16 mb-1">{{expertiseCard.details}}</p>
    <a [href]="expertiseCard?.expertiseUrl" class="btn-link know-more-link" target="_blank">{{expertiseCard?.expertiseUrlText}}</a>
  </div>
  <div class="expertise-card__foot">
    <img [src]="expertiseCard?.logo?.url" [alt]="expertiseCard?.title" />
  </div>
</div>
