import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpController: HttpClient) { }

  getHomeData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.home_page}`);
  }

  getFooterData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.footer_data}`);
  }

  getOurExpertiseData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.expertise_data}`)
  }

  getAboutUsData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.about_us_data}`)
  }

  getOurTeamsData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.out_teams}`)
  }

  getContactUsSectionData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.contact_us_section}`)
  }

  getContactUsPageData() {
    return this.httpController.get<any>(`${environment.baseUrl}${environment.contact_us_data}`)
  }

  sendEmailService(body: any) {
    return this.httpController.post<any>(`${environment.baseUrl}${environment.send_email}`, body)
  }

}
