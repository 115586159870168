<div class="page-wrapper page-wrapper--not-found">
    <img class="effact-circle" src="./../../../assets/images/support-bg.png" alt="Effact" />
    <img class="effact-circle bottom-right" src="./../../../assets/images/support-bg-bottom.png" alt="Effact" />
     <div class="not-found-section">
         <div class="container">
             <div class="not-found-inner">
            <h1>404</h1>
            <p>Page Not Found!</p>
            <a routerLink="/" class="btn btn-secondary text-uppercase">Go To Home Page</a> 
        </div>
        </div>
     </div>
  </div>
  