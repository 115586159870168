// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "https://api.eastbournegroup.com",
  API_KEY: "9A698yWoDxCqrdMAt3xuMNWPzC5iAB2LLTijjgXXQ9jwxqj12WlehnJAesxc",
  home_page: "/home-page",
  footer_data: "/contact-links",
  expertise_data: "/our-expertise",
  about_us_data: "/about-us-page",
  out_teams: "/our-teams",
  contact_us_section: "/contact-us-section",
  contact_us_data: "/contact-us-page",
  send_email: "/emails"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
