import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './Pages/about-us/about-us.component';
import { ContactUsComponent } from './Pages/contact-us/contact-us.component';
import { HomePageComponent } from './Pages/home-page/home-page.component';
import { NotFoundPageComponent } from './Pages/not-found-page/not-found-page.component';
import { OurExpertiseComponent } from './Pages/our-expertise/our-expertise.component';

const routes: Routes = [ 
  {
    path: '', 
    component: HomePageComponent, 
  },
  {
    path: 'our-expertise',
    component: OurExpertiseComponent,
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  }, 
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
