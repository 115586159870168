<div class="page-wrapper page-wrapper--ourexpertise">
  <img class="effact-circle" src="./../../../assets/images/support-bg.png" alt="Effact" />
  <img class="effact-circle bottom-right" src="./../../../assets/images/support-bg-bottom.png" alt="Effact" />
  <div class="section-head text-center">
    <span class="badge bg-primary text-uppercase mb-2">{{expertiseIntro?.tag}}</span>
    <h2>{{expertiseIntro?.title}}</h2>
  </div>
  <div class="ourexpertise-tab">
    <div class="container">
      <div class="tab-row" *ngIf="!!expertiseTab">
        <nav ngbNav [(activeId)]="active" #nav="ngbNav" class="nav-tabs">
          <ng-container [ngbNavItem]="i" *ngFor="let item of expertiseTab; index as i;" ngbNavItem>
            <div class="nav-item"><a ngbNavLink><span>{{item?.tabTitle}}</span></a></div>
            <ng-template ngbNavContent>
              <div class="row g-1 g-lg-3">
                <div *ngFor="let data of item?.expertiseItems;" class="col-12 col-md-6 col-lg-4">
                  <app-expertise-card cardDark="true" [expertiseCard]="data"></app-expertise-card>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>