<header class="header" [class.sticky]="sticky">
  <div class="container">
    <div class="header__wrapper">
      <div class="logo">
        <a href="/">
          <img src="../../../assets/images/logo.svg" alt="Logo" />
        </a>
      </div>
      <!-- <div class="nav-menu">
        <input type="checkbox" id="navi-toggle" [(ngModel)]="isChecked" class="nav-menu__checkbox" />
        <label for="navi-toggle" class="nav-menu__button">
          <span class="nav-menu__icon">
            <span class="nav-menu__icon-span">&nbsp;</span>
            <span class="nav-menu__icon-span">&nbsp;</span>
            <span class="nav-menu__icon-span">&nbsp;</span>
            <span class="nav-menu__icon-span">&nbsp;</span>
            <span class="nav-menu__icon-span">&nbsp;</span>
            <span class="nav-menu__icon-span">&nbsp;</span>
          </span>
        </label>
        <div class="nav-menu__background">&nbsp;</div>
        <ul class="menu-list">
          <li class="menu-list__item" *ngFor="let item of navbar">
            <a [routerLink]="[item.url]" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" (click)="resetMenu()"><span>{{item.label}}</span></a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</header>
