<section class="contact-us-section">
  <div class="container">
    <div class="contact-wrapper">
      <div class="details-col" *ngIf="contactData">
        <span class="badge bg-primary text-uppercase mb-2">{{contactData?.tag}}</span>
        <h2 class="text-break mb-3 mb-lg-6">{{contactData?.title}}</h2>
        <p class="text-18 mb-0">{{contactData?.details}}</p>
      </div>
      <form [formGroup]="contactForm">
        <div class="form-col">
          <div class="form-card form-group">
            <div class="form-floating">
              <input class="form-control" type="text" placeholder="Name" formControlName="username" [class.is-valid]="!username.invalid && (username.dirty || username.touched)" [class.is-invalid]="username.invalid && (username.dirty || username.touched)" id="username" />
              <label for="username">Name</label>
              <div class="invalid-feedback">Please enter a valid name</div>
            </div>
            <div class="form-floating">
              <input class="form-control" type="text" placeholder="Subject" formControlName="subject" id="subject" />
              <label for="subject">Subject</label>
            </div>
            <div class="form-floating">
              <input class="form-control" type="email" placeholder="Email" formControlName="email" [class.is-valid]="!email.invalid && (email.dirty || email.touched)" [class.is-invalid]="email.invalid && (email.dirty || email.touched)" id="email" />
              <label for="email">Email</label>
              <div class="invalid-feedback">Please enter a valid email</div>
            </div>
            <div class="form-floating">
              <textarea class="form-control" placeholder="Message" formControlName="message" id="message"></textarea>
              <label for="message">Message</label>
            </div>
            <div class="submit-row mt-6">
              <button type="submit" class="btn btn-primary" [disabled]="!contactForm.valid" (click)="submit()">Submit</button>
            </div>
            <div class="form-succes-message" *ngIf="submitted">
              <div class="valid-feedback show">Thank you for contacting us. Our team will reach you soon!</div> 
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
