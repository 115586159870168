<section class="banner-section" *ngIf="content">
  <div class="container">
    <div class="banner-wrapper">
      <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-lg-6 order-2  order-lg-1">
          <div class="banner-content">
            <span class="badge bg-primary text-uppercase mb-2">{{content?.tag}}</span>
            <h1 class="text-break">{{content?.title}}</h1>
            <p class="text-18 mb-3 mb-lg-6">{{content?.details}}</p>
            <!-- <a routerLink="/about-us" class="btn btn-primary text-uppercase">{{content?.linkText}}</a> -->
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6   text-center order-1 order-lg-2 mb-4 mb-lg-0">
          <img [src]="content?.thumbImg?.url" [alt]="content?.title" />
        </div>
      </div>
    </div>
  </div>
</section>
