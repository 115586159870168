import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sticky = false;
  isChecked = false;

  public navbar = [
    {
      label: 'Home',
      url: './',
      activeClass: 'active'
    },
    {
      label: 'Our Expertise',
      url: './our-expertise'
    },
    {
      label: 'About Us',
      url: './about-us'
    },
    {
      label: 'Contact Us',
      url: './contact-us'
    },
  ];
  constructor(private router: Router) { }

  ngOnInit(): void { }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 10) {
      this.sticky = true;
    }
    else {
      this.sticky = false;
    }
  }

  resetMenu() {
    this.isChecked = false;
  }
}
