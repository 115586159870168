import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  // data1 = [{
  //   name: 'Sam Johnson',
  //   dept: 'Electrical'
  // }, {
  //   name: 'Roy Thomas',
  //   dept: 'Mechanical'
  // }, {
  //   name: 'Jim Lasker',
  //   dept: 'Medical'
  // }];

}

