<footer class="footer">
  <div class="container">
    <div class="footer__top">
      <div class="row">
        <div class="col-12 col-lg-5 col-xl-6">
          <div class="foot-contact">
            <div class="footer-logo">
              <img src="../../../assets/images/logo.svg" alt="" />
            </div>
            <div class="contact-item">
              <a [href]="footerData?.mobile?.contact_no">
                <span class="icon">
                  <img src="../../../assets/images/contact-icon.svg" alt="Contact">
                </span>
                {{footerData?.mobile?.name}}
              </a>
            </div>
            <div class="contact-item">
              <a [href]="footerData?.email?.contact_email">
                <span class="icon">
                  <img src="../../../assets/images/email-icon.svg" alt="Email">
                </span>
                {{footerData?.email?.name}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-7 col-xl-6">
          <div class="row">
            <!-- <div class="col-12 col-md-6">
              <div class="foot-menu mb-2 mb-md-0">
                <h4 class="title mb-3 mb-lg-4">Quick Links</h4>
                <ul class="row">
                  <li class="col-12 col-md-6"><a routerLink="/">Home</a></li>
                  <li class="col-12 col-md-6"><a routerLink="/about-us">About Us</a></li>
                  <li class="col-12 col-md-6"><a routerLink="/our-expertise">Our Expertise</a></li>
                  <li class="col-12 col-md-6"><a routerLink="/contact-us">Contact Us</a></li>
                </ul>
              </div>
            </div> -->
            <div class="col-12 col-md-6">
              <div class="foot-menu" *ngIf="!!footerData?.socials?.Links">
                <h4 class="title mb-3 mb-lg-4">Social</h4>
                <ul class="row">
                  <li class="col-12 col-md-6"  *ngFor="let item of footerData?.socials?.Links"><a href="{{item?.linkUrl}}" target="_blank">{{item?.name}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="row">
        <div class="col-12 col-lg-6">
          <p class="copyright m-0 text16">
            Copyright © 2021 EastBourne. All right reserved.
          </p>
        </div>
        <div class="col-12 col-lg-6">
          <ul class="foot-privacy">
            <li><a href="#">Terms & Condition</a></li>
            <li><a href="#">Privacy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
