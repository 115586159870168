<div class="page-wrapper page-wrapper--home">
  <img class="effact-circle" src="./../../../assets/images/support-bg.png" alt="Effact" />
  <img class="effact-circle bottom-right" src="./../../../assets/images/support-bg-bottom.png" alt="Effact" />
  <app-banner-section [content]="content"></app-banner-section>
  <section class="our-planning-sec" *ngIf="!!planningData">
    <div class="container">
      <div class="our-planning-wrapper">
        <div class="planning-head">
          <div class="row align-items-center">
            <div class="col">
              <span class="badge bg-primary text-uppercase mb-2">{{planningData?.tag}}</span>
              <h2>{{planningData?.title}}</h2>
              <p class="text-18 mb-3 mb-lg-0">{{planningData?.details}}</p>
            </div>
            <div class="col-12 col-lg-auto">
              <a routerLink="/contact-us" class="btn btn-primary text-uppercase">{{planningData?.linkText}}</a>
            </div>
          </div>
        </div>
        <div class="planning-grid">
          <div>
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config] ="slideConfig">
                <div ngxSlickItem class="slick-slider-item" *ngFor="let item of planningData.PlanningCardsHome;">
                  <app-planning-card [content]="item"></app-planning-card>
                </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="expertise-sec" *ngIf="!!expertiseData">
    <div class="container">
      <div class="our-planning-wrapper">
        <div class="planning-head text-center">
          <span class="badge bg-primary text-uppercase mb-2">{{expertiseData?.tag}}</span>
          <h2>{{expertiseData?.details}}</h2>
        </div>
        <div class="details-content">
          <div class="row g-1 g-lg-3">
            <div *ngFor="let item of expertiseItems;" class="col-12 col-md-6 col-lg-4">
              <app-expertise-card [expertiseCard]="item"></app-expertise-card>
            </div>
            <div class="col-12 col-md-12 col-lg-8">
              <div class="investing-card">
                <div class="investing-card__wrap">
                  <h2 class="mb-3 mb-lg-4">{{expertiseData?.title}}</h2>
                  <!-- <a routerLink="/our-expertise" class="btn btn-primary text-uppercase">{{expertiseData?.linkText}}</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="perspective-sec" *ngIf="!!whatWeDoData">
    <div class="container">
      <div class="perspective-wrapper">
        <div class="perspective-wrapper__content">
          <span class="badge bg-primary text-uppercase mb-2">{{whatWeDoData?.tag}}</span>
          <h2 class="text-break mb-3 mb-lg-4">{{whatWeDoData?.title}}</h2>
          <!-- <a routerLink="/about-us" class="btn btn-primary text-uppercase">{{whatWeDoData?.linkText}}</a> -->
        </div>
        <div class="perspective-wrapper__thumb">
          <img [src]="whatWeDoData?.thumbImg?.url" [alt]="whatWeDoData?.title" />
        </div>
      </div>
    </div>
  </section>
  <section class="h-contactus-sec" *ngIf="contactData">
    <div class="container">
      <div class="hcontact-wraper">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="hcontact-wraper__thumb">
              <img [src]="contactData?.thumbImg?.url" [alt]="contactData?.tag">
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="hcontact-wraper__content">
              <span class="badge bg-primary text-uppercase mb-2">{{contactData?.tag}}</span>
              <h2 class="text-break mb-3 mb-lg-4">{{contactData?.title}}</h2>
              <p class="text-18 mb-3 mb-lg-4">{{contactData?.details}}</p>
              <!-- <a routerLink="/contact-us" class="btn btn-primary text-uppercase">{{contactData?.linkText}}</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
