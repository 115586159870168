// Module
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Components
import { AppComponent } from './app.component';
import { HomePageComponent } from './Pages/home-page/home-page.component';
import { HeaderComponent } from './shared-component/header/header.component';
import { FooterComponent } from './shared-component/footer/footer.component';
import { BannerSectionComponent } from './shared-component/banner-section/banner-section.component';
import { PlanningCardComponent } from './shared-component/planning-card/planning-card.component';
import { ExpertiseCardComponent } from './shared-component/expertise-card/expertise-card.component';
import { OurExpertiseComponent } from './Pages/our-expertise/our-expertise.component';
import { AboutUsComponent } from './Pages/about-us/about-us.component';
import { OurTeamCardComponent } from './shared-component/our-team-card/our-team-card.component';
import { ContactUsSectionComponent } from './shared-component/contact-us-section/contact-us-section.component';
import { ContactUsComponent } from './Pages/contact-us/contact-us.component';
import { ApiKeyInterceptor } from './services/request.interceptor';
import { CommonModule } from '@angular/common';
import { NotFoundPageComponent } from './Pages/not-found-page/not-found-page.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    BannerSectionComponent,
    PlanningCardComponent,
    ExpertiseCardComponent,
    OurExpertiseComponent,
    AboutUsComponent,
    OurTeamCardComponent,
    ContactUsSectionComponent,
    ContactUsComponent,
    NotFoundPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SlickCarouselModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor , multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
