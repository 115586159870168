import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact-us-section',
  templateUrl: './contact-us-section.component.html',
  styleUrls: ['./contact-us-section.component.scss']
})
export class ContactUsSectionComponent implements OnInit {

  contactData: any;
  submitted: boolean = false;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.getContctSectionData();
  }

  contactForm : FormGroup = new FormGroup({
    username: new FormControl('', [
      Validators.required, 
      Validators.pattern(/^[a-zA-Z\s]+(([',. -][a-zA-Z\s])?[a-zA-Z\s]*)*$/i)
    ]),
    subject: new FormControl('', [
      Validators.pattern(/^[a-zA-Z0-9\s]+(([',. -][a-zA-Z0-9\s])?[a-zA-Z0-9\s]*)*$/i)
    ]),
    email: new FormControl('', [
      Validators.required, 
      Validators.email,
      Validators.pattern(/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,8}(?:\.[a-z]{2})?)$/i)
    ]),
    message: new FormControl('')
  });

  getContctSectionData() {
    this.dataService.getContactUsSectionData().subscribe({
      next: (res : any) => {
        this.contactData = res;
      },
      error: (error : any) => {
        console.log(error);
      }
    });
  }

  clearForm () {
    this.contactForm.reset();
  }

  get email() { return this.contactForm.controls.email; }
  get username() { return this.contactForm.controls.username; }

  submit() {
    
    this.dataService.sendEmailService(this.contactForm.value).subscribe({
      next: (data : any) => {
        this.submitted = true;
        this.clearForm();
        setTimeout(() => {
          this.submitted = false;
        }, 4000);
      },
      error: (error : any) => {
        console.log(error);
      }
    })
    
  }

}
