import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-our-expertise',
  templateUrl: './our-expertise.component.html',
  styleUrls: ['./our-expertise.component.scss']
})
export class OurExpertiseComponent implements OnInit {
  active = 0;
  expertiseTab : any;
  expertiseIntro : any;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.getExpertiseData();
  }

  getExpertiseData() {
    this.dataService.getOurExpertiseData().subscribe({
      next: (res : any) => {
        const data = res[0];
        this.expertiseIntro = data?.OurExpertiseIntro;
        this.expertiseTab = [...this.getAllCategoryExpertise(data?.OurExpertise), ...this.serializeExpertiseData(data?.OurExpertise)];
      },
      error: (error : any) => {
        console.log(error);
      }
    });
  }

  getAllCategoryExpertise(OurExpertise: any) {
    let allExpertise: any = [];
    OurExpertise.forEach((element : any) => {
      allExpertise = [...allExpertise, ...element?.expertise_category?.expertise_types];
    });

    return [{
      tabTitle: 'All',
      expertiseItems: allExpertise.map((expertise : any) => {
        return {
          tag: expertise?.tag,
          title: expertise?.title,
          expertiseUrl: `https://${expertise?.expertiseUrl}`,
          expertiseUrlText: expertise?.expertiseUrlText,
          country: expertise?.country,
          details: expertise?.details,
          logo: { url: `${environment?.baseUrl}${expertise?.logo?.url}` }
        }
      })
    }];
  }

  serializeExpertiseData(OurExpertise: any) {
    let serializedExpertiseData: any = [];
    OurExpertise.forEach((expertiseContent : any) => {
      const obj = {
        tabTitle: expertiseContent?.expertise_category?.name,
        expertiseItems: expertiseContent?.expertise_category?.expertise_types.map((expertise : any) => {
          return {
            tag: expertise?.tag,
            title: expertise?.title,
            expertiseUrl: `https://${expertise?.expertiseUrl}`,
            expertiseUrlText: expertise?.expertiseUrlText,
            country: expertise?.country,
            details: expertise?.details,
            logo: { url: `${environment?.baseUrl}${expertise?.logo?.url}` }
          }
        })
      };
      serializedExpertiseData = [...serializedExpertiseData, obj];
    });
    
    return serializedExpertiseData;
  }

}
