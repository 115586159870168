import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public content : any;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.getContactUsPageData();
  }

  getContactUsPageData() {
    this.dataService.getContactUsPageData().subscribe({
      next: (res: any) => {
        this.content = res.AboutUsContact;
        this.content.thumbImg.url = `${environment.baseUrl}${this.content?.thumbImg?.url}`;
      },
      error: (error : any) => {
        console.log(error);
      }
    })
  }

}
