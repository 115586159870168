import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutUsIntro: any;
  description: any;
  ourTeamData: any = {};
  selectedMember: any;

  constructor(private modalService: NgbModal, private dataService: DataService) { }

  open(content: any, i: any) {
    this.selectedMember = this.ourTeamData.team_members[i];
    this.modalService.open(content, { ariaLabelledBy: 'team', windowClass: 'team-modal' });
  }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData() {
    Promise.all([this.dataService.getAboutUsData().toPromise(), this.dataService.getOurTeamsData().toPromise()]).then(([aboutUsData, teamData] : any) => {
      this.aboutUsIntro = aboutUsData[0].intro;
      this.aboutUsIntro.thumbImg.url = `${environment.baseUrl}${this.aboutUsIntro?.thumbImg?.url}`;

      this.description = aboutUsData[0].aboutUsDescription;
      this.description.leftThumbImg.url = `${environment.baseUrl}${this.description?.leftThumbImg?.url}`;
      this.description.rightThumbImg.url = `${environment.baseUrl}${this.description?.rightThumbImg?.url}`;

      this.ourTeamData = aboutUsData[0].OurTeam;
      this.ourTeamData?.team_members.forEach((element : any) => {
        element.profileThumbImg = { url : "" };
      });

      teamData.forEach((teamMember : any) => {
        teamMember.profileThumbImg.url = `${environment.baseUrl}${teamMember?.profileThumbImg?.url}`;
      });
      
      this.ourTeamData.team_members = teamData;
    }).catch((error: any) => {
      console.log(error);
    });
  }

}
