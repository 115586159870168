import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  content : any;
  planningData : any;
  cardItems : any;
  expertiseData : any;
  expertiseItems : any;
  whatWeDoData : any;
  contactData : any;

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.getHomeData();
  }

  slideConfig: any = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "arrows": false,
    "dots": true,
    "infinite": false,
    "responsive": [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true,
        },
      },
    ],
  };

  getHomeData() {
    this.dataService.getHomeData().subscribe({
      next: (res : any) => {
        const data = res[0];
        this.content = data.AboutUsHome;
        this.content.thumbImg.url = `${environment.baseUrl}${this.content.thumbImg.url}`;
        
        this.planningData = data?.PlanningHome;
        this.cardItems = data?.PlanningHome?.PlanningCardsHome;
        this.cardItems?.forEach((content : any) => {
          content.thumbImg.url = `${environment.baseUrl}${content?.thumbImg?.url}`;
        });
        
        this.expertiseData = data?.ExpertiseHome?.ExpertiseCardHome;
        this.expertiseItems = data?.ExpertiseHome?.experts;
        this.expertiseItems.forEach((expertiseCard : any) => {
          expertiseCard.expertiseUrl = `https://${expertiseCard?.expertiseUrl}`
          expertiseCard.logo.url = `${environment.baseUrl}${expertiseCard?.logo?.url}`
        });

        this.whatWeDoData = data?.WhatWeDoHome;
        this.whatWeDoData.thumbImg.url = `${environment.baseUrl}${this.whatWeDoData?.thumbImg?.url}`;

        this.contactData = data?.ContactUsHome;
        this.contactData.thumbImg.url = `${environment.baseUrl}${this.contactData?.thumbImg?.url}`;
      },
      error: (error : any) => {
        console.log(error);
        
      }
    });
  }

}
