<div class="page-wrapper page-wrapper--aboutus">
  <img class="effact-circle" src="./../../../assets/images/support-bg.png" alt="Effact" />
  <img class="effact-circle bottom-right" src="./../../../assets/images/support-bg-bottom.png" alt="Effact" />
  <section class="banner-section">
    <div class="container" *ngIf="aboutUsIntro">
      <div class="section-head">
        <div class="title-col">
          <span class="badge bg-primary text-uppercase mb-2">{{aboutUsIntro?.tag}}</span>
          <h1 class="h2 text-break">{{aboutUsIntro?.title}}</h1>
        </div>
        <div class="info-col">
          <p class="text-18 mb-0">{{aboutUsIntro?.details}}</p>
        </div>
      </div>
      <div class="about-banner">
        <img [src]="aboutUsIntro?.thumbImg?.url" [alt]="aboutUsIntro?.tag">
      </div>
    </div>
  </section>
  <section class="about-sec">
    <div class="container">
      <div class="about-wrapper" *ngIf="description">
        <div class="about-row first-row">
          <div class="about-thumb">
            <img [src]="description?.rightThumbImg?.url" alt="About">
          </div>
          <div class="about-content">
            <p class="text-18" *ngFor="let item of description?.leftDescription">{{item?.description}}</p>
          </div>
        </div>
        <div class="about-row second-row">
          <div class="about-thumb">
            <img [src]="description?.leftThumbImg?.url" alt="About">
          </div>
          <div class="about-content">
            <ul class="ml-0 pl-0 mb-4 mb-lg-6" *ngFor="let item of description?.rightDescription">
              <li>{{item?.description}}</li>
            </ul>
            <a routerLink="/contact-us" class="btn btn-primary text-uppercase">{{description?.linkText}}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="our-team-section">
    <div class="container">
      <div class="our-team-wrapper" *ngIf="ourTeamData?.team_members">
        <div class="ourteam-head text-center">
          <span class="badge bg-primary text-uppercase mb-2">{{ourTeamData?.tag}}</span>
          <h2>{{ourTeamData?.title}}</h2>
        </div>
        <div class="details-content">
          <div class="row g-2">
            <div *ngFor="let item of ourTeamData?.team_members; let i = index" (click)="open(content, i)" class="col-12 col-md-6 col-lg-4">
              <app-our-team-card [teamCard]="item"></app-our-team-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-contact-us-section></app-contact-us-section>
  <ng-template windowClass="team-modal" #content let-modal>
    <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <img src="../../../assets/images/close-icon.svg" alt="Close" />
    </button>
    <div class="modal-body">
      <div class="user-profile">
        <div class="user-thumb">
          <img [src]="selectedMember?.profileThumbImg?.url" [alt]="selectedMember?.name">
        </div>
        <div class="user-details">
          <h3 class="mb-1 name">{{selectedMember?.name}}</h3>
          <p class="text-14 designation">({{selectedMember?.designation}})</p>
          <div class="social-list">
            <a *ngFor="let item of selectedMember?.OurTeamSocials" [href]="item?.linkUrl" target="_blank"><img src="../../../assets/images/{{item?.tag}}-icon.svg" [alt]="item?.tag"></a>
          </div>
        </div>
      </div>
      <div class="user-content">
        <p class="text-18" *ngFor="let item of selectedMember?.teamDescription">{{item?.description}}</p>
      </div>
    </div>
  </ng-template>

</div>
