import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerData: any;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getFooterData().subscribe({
      next: data => {
        this.footerData = data;
      },
      error: (error) => {
        console.log(error);
      }
    });
  }

}
